<template>
  <RouterView />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import { onMounted, onUnmounted } from 'vue'
import { useModal } from '@/composables/useModal';

function handleRenderError(event: Event) {
  if (event instanceof CustomEvent && event.type === 'rendererror') {
    useModal(
      'Er is iets mis gegaan',
      '<div class="flex flex-col gap-4"><p>Er is een fout opgetreden tijdens het laden van het 3D model. Vernieuw de pagina om dit te verhelpen.</p><p>Werkt dit niet? <a class="text-brand-green" href="https://opuspod.nl/contact" target="_blank">Neem dan contact met ons op</a>.</p><button class="button-orange-contained md:w-fit" onclick="location.reload()">Opnieuw proberen</button></div>')
  }
}

function handle3DError() {
  useModal(
    'Er is iets mis gegaan',
    '<div class="flex flex-col gap-4"><p>Er is een fout opgetreden tijdens het laden van het 3D model. Klik op de knop om het opnieuw te proberen.<br><br>Het is ook mogelijk dat jouw browser of apparaat de 3D weergave niet ondersteund, probeer het dan op een ander apparaat.</p><p>Werkt dit niet? We helpen je natuurljk graag.<br>Laat <a class="underline text-brand-green" href="https://opuspod.nl/contact" target="_blank">hier je contactgegevens achter</a> en we nemen snel contact met je op.</p><button class="button-orange-contained md:w-fit" onclick="location.reload()">Opnieuw proberen</button></div>')
}

onMounted(() => {
  // Listen for rendererror events on the window
  window.addEventListener('rendererror', handleRenderError)
  window.addEventListener('no3DSupport', handle3DError)
})

// Remove the listener
onUnmounted(() => {
  window.removeEventListener('rendererror', handleRenderError)
  window.removeEventListener('no3DSupport', handle3DError)
})

</script>